<template>
  <Content>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="2" style="max-width: 1800px" size="small">
              <a-step :title="$t('logistics.create_transport_plan')" />
              <a-step :title="$t('logistics.set_transport_info')" />
              <a-step :title="$t('logistics.confirm_transport_plan')" />
              <a-step class="mr-3" :title="$t('warehouse.order_placed')" />
            </a-steps>
          </a-col>
        </a-row>
        <!-- 仓库信息 -->
        <div class="inputs mt-3 mb-3">
          <a-row :gutter="[0, 12]">
            <div class="ant-form-inline">
              <a-form-item :label="$t('logistics.transport_plan_name')">
                <span v-if="!isChangingPlanName">{{ plan.name }}</span>
                <a-input
                  v-else
                  ref="refPlanName"
                  size="small"
                  @pressEnter="handleSavePlanName"
                  @focus="
                    (event) => {
                      event.currentTarget.select();
                    }
                  "
                  v-model:value="plan.name"
                />
              </a-form-item>
              <a-form-item>
                <a-button
                  v-if="!isChangingPlanName"
                  type="primary"
                  size="small"
                  @click="handleButtonAlterPlanName"
                  ghost
                  >{{ $t("account.modify") }}</a-button
                >
                <a-button
                  v-else
                  type="primary"
                  size="small"
                  @click="handleSavePlanName"
                  :loading="buttonSavePlanNameLoading"
                  >{{ $t("common.save") }}</a-button
                >
              </a-form-item>
              <a-form-item>
                <a-button
                  v-if="isChangingPlanName"
                  type="primary"
                  size="small"
                  @click="handleCancelAlterPlanName"
                  ghost
                  >{{ $t("common.cancel") }}</a-button
                >
              </a-form-item>
            </div>
            <a-col :span="24">
              <label>{{ $t("warehouse.transportation_plan_number") }}:</label>
              {{ plan.no }}
            </a-col>
            <a-col :span="10">
              <label>{{ $t("logistics.destination_type") }}:</label>
              {{ $t($enumLangkey("destinationType", plan.toWarehouseType)) }}
            </a-col>
            <a-col
              v-if="plan.toWarehouseType == destinationTypeEnum.FBA"
              :span="6"
            >
              <label>{{ $t("logistics.fba_warehouse_code") }}:</label>
              {{ plan.toWarehouseCode }}
            </a-col>
            <a-col
              v-else-if="
                plan.toWarehouseType == destinationTypeEnum.mercadolibre
              "
              :span="6"
            >
              <label>{{ $t("warehouse.fbm_warehouse_code") }}:</label>
              {{ plan.toWarehouseCode }}
            </a-col>

            <a-col
              v-else-if="
                plan.toWarehouseType == destinationTypeEnum.SeWarehouse
              "
              :span="6"
            >
              <label>{{ $t("logistics.warehouse_code") }}:</label>
              {{ plan.toWarehouseCode }}
            </a-col>
            <a-col
              v-else-if="
                plan.toWarehouseType == destinationTypeEnum.OtherAddress
              "
              :span="6"
            >
              <label>{{ $t("warehouse.destination") }}:</label>
              {{ plan.toNikeName || "-" }}
            </a-col>
            <a-col :span="24">
              <label>{{ $t("logistics.shipping_address") }}:</label>
              {{ getAddress(plan.toAddressData) }}
              <span
                class="ml-5"
                v-if="
                  plan.toAddressData &&
                  plan.toWarehouseType == destinationTypeEnum.SeWarehouse
                "
                >{{ $t("logistics.contacts") }}:
                {{ getLinkman(plan.toAddressData) }}</span
              >
            </a-col>

            <a-col :span="24">
              <label>{{ $t("logistics.originate") }}:</label>
              {{ plan.fromAddressData.fromName }}
            </a-col>
            <a-col :span="24">
              <label>{{ $t("logistics.shipments_address") }}:</label>
              {{ getAddress(plan.fromAddressData) }}
              <a-tag
                color="warning"
                v-if="plan.toAddressData.isInternationalTransport"
              >
                {{ $t("warehouse.international_transport") }}</a-tag
              >
              <a-tag color="warning" v-else>
                {{ $t("logistics.local_transport") }}</a-tag
              >
              <a-checkbox
                v-if="
                  plan.isShowDoorTake &&
                  plan.totalContainerWeight >= plan.minShippingWeight
                "
                v-model:checked="plan.isSelectDoorTake"
              >
                {{ $t("logistics.address_supports_door_to_door_service") }}
              </a-checkbox>
            </a-col>
            <!-- 发货联系人 -->
            <a-col :span="24" v-if="plan.isSelectDoorTake">
              <a-form layout="inline">
                <a-form-item>
                  <template #label>
                    <div>
                      <span style="color: red"> * </span>
                      <label>{{ $t("logistics.shipments_contacts") }}</label>
                    </div>
                  </template>
                  <a-input v-model:value="plan.takeName" style="width: 300px" />
                </a-form-item>
                <a-form-item>
                  <template #label>
                    <div>
                      <span style="color: red"> * </span>
                      <label>{{ $t("logistics.contact_number") }}</label>
                    </div>
                  </template>
                  <a-input
                    v-model:value="plan.takePhone"
                    style="width: 300px"
                  />
                </a-form-item>
              </a-form>
            </a-col>
            <a-col :span="10">
              <label>
                {{ $t("logistics.logistics_center_harvest_address") }}
                <c-tooltip :title="$t('logistics.logistics_select_hint')" />:
              </label>
              {{ getAddress(plan.seLogisticsAddress) }}
            </a-col>
            <a-col :span="6">
              <label>{{ $t("logistics.contacts") }}:</label>
              {{ plan.seLogisticsContact }}
            </a-col>
            <a-col :span="6">
              <label>{{ $t("logistics.contact_number") }}:</label>
              {{ plan.seLogisticsPhone }}
            </a-col>

            <a-col
              :span="24"
              v-if="plan.toWarehouseType == destinationTypeEnum.FBA"
            >
              <label>{{ $t("warehouse.amazon_shipping_number") }}:</label>
              {{ plan.fbaTransportNo }}
            </a-col>
            <a-col
              :span="24"
              v-if="plan.toWarehouseType == destinationTypeEnum.mercadolibre"
            >
              <label>{{ $t("warehouse.seller_id") }}:</label>
              {{ plan.fbaTransportNo }}
            </a-col>

            <a-col
              :span="24"
              v-if="plan.toWarehouseType == destinationTypeEnum.FBA"
            >
              <label>{{ $t("warehouse.shipment_tracking_number") }}:</label>
              {{ plan.productTrackNo }}
            </a-col>

            <a-col
              :span="24"
              v-if="plan.toWarehouseType == destinationTypeEnum.mercadolibre"
            >
              <label>{{ $t("warehouse.warehousing_number") }}:</label>
              {{ plan.productTrackNo }}
            </a-col>

            <a-col :span="24">
              <a-form layout="inline">
                <a-form-item>
                  <template #label>
                    <div>
                      <span style="color: red"> * </span>
                      <label>{{ $t("logistics.recipients") }}</label>
                    </div>
                  </template>
                  <a-input
                    v-model:value="plan.toName"
                    :disabled="!!plan.toAddressData.linkMan"
                    v-user-name-filter
                    style="width: 300px"
                  />
                </a-form-item>
                <a-form-item :label="$t('logistics.contact_number')">
                  <a-input-group style="display: flex; width: 300px" compact>
                    <a-select
                      style="width: 100px"
                      v-model:value="plan.receiptPhoneCountryId"
                      :placeholder="$t('logistics.area_code')"
                      :loading="phoneCountryLoading"
                      option-label-prop="label"
                      optionFilterProp="search-key"
                      show-search
                    >
                      <a-select-option
                        v-for="(item, index) in phoneCountryList"
                        :key="index"
                        :value="item.id"
                        :label="`+${item.telCode}`"
                        :search-key="
                          item.ioS2 +
                          item.cnName +
                          item.enName +
                          '+' +
                          item.telCode
                        "
                        :title="`+${item.telCode}(${getLanguageName(item)})`"
                        >+{{ item.telCode }}({{
                          getLanguageName(item)
                        }})</a-select-option
                      >
                    </a-select>
                    <a-input
                      style="flex: 1"
                      v-model:value="plan.toPhone"
                      :placeholder="$t('warehouse.phone')"
                      @change="checkPhoneNumber"
                    />
                  </a-input-group>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </div>
        <!-- 箱表格 -->
        <div>
          <a-table
            :columns="plan.boxTableColumns"
            :data-source="plan.producTableList"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize: 10,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
          >
            <template #encasementSize>
              <span>
                {{ $t("warehouse.encasement_size") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_4')" />
              </span>
            </template>
            <template #encasementGrossWeight>
              <span>
                {{ $t("warehouse.encasement_weight") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_5')" />
              </span>
            </template>
            <template #encasementCount>
              <span>
                {{ $t("warehouse.encasement_count") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_1')" />
              </span>
            </template>
            <template #inWarehouseBoxCount>
              <span>
                {{ $t("logistics.transport_box_number") }}
              </span>
            </template>

            <template #boxNo="{ record }">
              <div>
                {{ record.boxNo }}
                <CTagMixedOrSingle
                  :productKindCount="record.productKindCount * 1"
                ></CTagMixedOrSingle>
              </div>
              <div class="mt-1">
                <UserDefinedBoxNo
                  :boxId="record.whBoxId"
                  :userDefinedBoxNo="record.selfBoxNo"
                ></UserDefinedBoxNo>
              </div>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation
                :open="expanded"
                @click="(e) => onExpand(record, e)"
              />
            </template>

            <template #encasementCountCustom="{ record }">
              {{ record.containerCount }} Unit
            </template>

            <template #encasementSizeCustom="{ record }">
              {{ record.packBoxInfo.length }}x{{ record.packBoxInfo.width }}x{{
                record.packBoxInfo.height
              }}cm
            </template>

            <template #encasementGrossWeightCustom="{ record }">
              {{ gToKg(record.packBoxInfo.weight) }}kg
            </template>

            <template #print>
              <span>
                <!-- <a-button
                  @click="handleBulkPrint"
                  :loading="bulkPrintLoading"
                  >{{ $t("common.batch_print") }}</a-button
                > -->
              </span>
            </template>
            <template #printCustom="{ record }">
              <!-- <a-button
                @click="handlePrintBoxLabel(record)"
                :loading="record.loading"
              >
                <span
                  v-if="plan.toWarehouseType == destinationTypeEnum.SeWarehouse"
                >
                  {{ $t("common.se_outer_box_label") }}
                </span>
                <span v-else>
                  {{ $t("common.print_box_label") }}
                </span>
              </a-button> -->
            </template>

            <template #footer>
              <a-row type="flex" justify="start" class="mr-5" :gutter="32">
                <a-col
                  >{{ $t("common.total") }}:{{ plan.boxTotalCount
                  }}{{ $t("warehouse.box") }}</a-col
                >
                <a-col v-if="plan.transportTotalVolume > 0"
                  >{{ $t("warehouse.total_volume_of_transport") }}:{{
                    plan.transportTotalVolume
                  }}m<sup>3</sup></a-col
                >
                <a-col v-if="plan.transportTotalWeight > 0"
                  >{{ $t("warehouse.gross_shipping_weight") }}:{{
                    plan.transportTotalWeight
                  }}kg</a-col
                >
                <a-col
                  >{{ $t("warehouse.total_volume") }}:
                  {{ plan.totalContainerVolume }}m<sup>3</sup></a-col
                >
                <a-col
                  >{{ $t("warehouse.total_encasement_weight") }}:
                  {{ plan.totalContainerWeight }}kg</a-col
                >

                <a-col>
                  {{ $t("warehouse.transport_characteristics") }}:
                  <span
                    v-if="
                      plan.transportTotalCharacteristic &&
                      !!plan.transportTotalCharacteristic.length
                    "
                  >
                    <a-tag
                      color="default"
                      v-for="item in plan.transportTotalCharacteristic"
                      :key="item"
                    >
                      {{ $t($enumLangkey("transportCharacteristics", item)) }}
                    </a-tag>
                  </span>
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="plan.innerColumns"
                :data-source="record.productInfos"
                :pagination="false"
                :row-key="(record) => record.productId"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.productName }}</div>
                      <div>{{ record.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #containerCount="{ record }">
                  {{ record.containerCount }} Unit
                </template>
                <template #restsCustom="{ record }">
                  <a-row :gutter="[0, 2]">
                    <a-col :span="24">
                      <a-row type="flex" justify="space-between">
                        <a-col>{{
                          $t("warehouse.customs_clearance_info")
                        }}</a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="24">
                      <a-input
                        :addon-before="$t('warehouse.chinese_trade_name')"
                        size="small"
                        :disabled="true"
                        v-model:value="record.customsInfo.brandCName"
                      />
                    </a-col>
                    <a-col :span="24">
                      <a-input
                        :addon-before="$t('warehouse.english_trade_name')"
                        size="small"
                        :disabled="true"
                        v-model:value="record.customsInfo.brandEnName"
                      />
                    </a-col>
                    <a-col :span="24">
                      <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                          <a-input
                            addon-before="HS  Code"
                            size="small"
                            :disabled="true"
                            v-model:value="record.customsInfo.hsCode"
                          />
                        </a-col>
                        <a-col :span="12">
                          <a-input-group compact>
                            <div
                              style="
                                border: 1px solid #d9d9d9;
                                padding: 0 11px;
                                background-color: #fff;
                              "
                            >
                              {{ $t("warehouse.declare_price") }}
                            </div>
                            <a-select
                              v-model:value="record.customsInfo.currencyId"
                              size="small"
                              :disabled="true"
                            >
                              <a-select-option
                                v-for="item in currencies"
                                :key="item.id"
                                :value="item.id"
                                >{{ item.symbol }}</a-select-option
                              >
                            </a-select>
                            <a-input
                              style="width: 30%"
                              size="small"
                              :disabled="true"
                              :value="
                                $filters.amountToFixed2(
                                  record.customsInfo.totalPrice
                                )
                              "
                            />
                          </a-input-group>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </template>
          </a-table>
        </div>
        <!-- 清关信息汇总 -->
        <div v-if="plan.toAddressData.isInternationalTransport">
          <p>
            <strong>{{
              $t("logistics.summary_customs_clearance_information")
            }}</strong>
          </p>
          <a-table
            :columns="customsInfoColumns"
            :data-source="customsInfo"
            :scroll="{ x: 800 }"
            size="small"
            :pagination="false"
          >
            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
              </span>
            </template>

            <template #productNameAndSeSkuCustom="{ record }">
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </template>

            <template #tradeNameCustom="{ record }">
              <div>
                {{ record.enTradeNameCustom }}
              </div>
              <div>
                {{ record.chTradeNameCustom }}
              </div>
            </template>

            <template #texture="{ record }">
              <div>
                {{ record.enMaterial }}
              </div>
              <div>
                {{ record.cnMaterial }}
              </div>
            </template>

            <template #brand="{ record }">
              {{ record.brand || "-" }}
            </template>

            <template #purposeDescription="{ record }">
              <div>{{ record.purposeEnDescription || "-" }}</div>
              <div>{{ record.purposeCnDescription || "-" }}</div>
            </template>

            <template #applyMiddleRate="{ record }">
              {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.applyMiddleRate) }}
            </template>
            <template #subtotal="{ record }">
              {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.subtotal) }}
            </template>
          </a-table>
        </div>
        <!-- 物流方案 -->
        <div class="mt-3">
          <p>
            <strong>{{ $t("logistics.logistics_channel") }}</strong>
          </p>
          <div class="mb-5">
            <a-table
              :columns="logistics.columnsLogisticsScheme"
              :data-source="logistics.logisticsSchemeList"
              :scroll="{ x: true }"
              size="small"
              :pagination="false"
              :loading="logisticsSchemeLoading"
            >
              <template #logisticsScheme>
                <span>
                  {{ $t("warehouse.logistics_scheme") }} /
                  {{ $t("warehouse.logistics_code") }}
                </span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("warehouse.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("warehouse.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("warehouse.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span>
                  {{ $t("warehouse.freight_estimate") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 8]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined
                      class="text-warning mr-1"
                      :style="{ fontSize: '12px' }"
                    />
                    <span
                      style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                    >
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{ record.daysMin }}-{{ record.daysMax }}
                  {{ $t("warehouse.workday") }}
                  ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{ $t("warehouse.average_unit_price") }}:
                  {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.averagePrice) }}
                </div>
                <div class="text-warning">
                  {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.expressFee) }}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod == 0">
                  {{ $t("logistics.chargeable_weight") }}:
                  {{ record.chargeableWeight }}kg
                </div>
                <div v-else>
                  {{ $t("logistics.chargeable_volume") }}:
                  {{ record.chargeableVolume }}m<sup>3</sup>
                </div>
                <div>
                  {{ $t("logistics.volume_weight_coefficient")
                  }}{{ record.volumeWeightParam }}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <template
                  v-if="
                    record.logisticsRegionInfo.isChooseDdp ||
                    record.logisticsRegionInfo.isChooseSign
                  "
                >
                  <div v-if="record.logisticsRegionInfo.isChooseDdp">
                    DDP({{ record.currencySymbol
                    }}{{
                      $filters.amountToFixed2(
                        record.logisticsRegionInfo.ddpFee
                      )
                    }})
                  </div>
                  <CSignSupported
                    v-if="record.logisticsRegionInfo.isChooseSign"
                    v-model:value="record.logisticsRegionInfo.signatureType"
                    type="readonly"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                  />
                </template>
                <span v-else>-</span>
              </template>
              <!-- 卡车专属 -->
              <template #quoteOptionsTitle="{ record }">
                {{ $t("logistics.quote_options") }}
                <CTooltip :title="$t('logistics.quote_options_tips')" />
              </template>
              <template #quoteOptions="{ record }">
                <a-row :gutter="[16, 8]">
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.quote_type")
                      }}</a-col>
                      <a-col flex="1">
                        <span>{{ record.truckBaseInfo.quoteType?.name }}</span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24" v-if="record.truckBaseInfo.serviceLevel">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.service_level")
                      }}</a-col>
                      <a-col flex="1">
                        <template>
                          <span>{{
                            record.truckBaseInfo.serviceLevel.name
                          }}</span>
                          <span>-</span>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.additional_options")
                      }}</a-col>
                      <a-col flex="1">
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px">
                              {{
                                getAccessorialsName(
                                  record.truckBaseInfo.accessorials
                                )
                              }}
                            </div>
                          </template>
                          <div
                            style="
                              max-width: 250px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            {{
                              getAccessorialsName(
                                record.truckBaseInfo.accessorials
                              )
                            }}
                          </div>
                        </a-popover>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </template>
              <template #quoteDetails="{ record }">
                <span v-if="record.truckBaseInfo"
                  >{{ record.truckBaseInfo.carrierName }} [{{
                    $filters.formatCurrencyAmount(
                      record.truckBaseInfo.currencySymbol,
                      record.truckBaseInfo.totalCharge,
                      false
                    )
                  }},
                  {{ handleParseTime(record.truckBaseInfo.transitTime) }}
                  day(s)]</span
                >
                <span v-else>-</span>
              </template>
            </a-table>

            <div class="mt-2">
              <a-tag color="warning" v-if="logistics.isRemoteArea">{{
                $t("logistics.remote_site")
              }}</a-tag>
              <template v-if="logistics.isCommercial != null">
                <a-tag color="warning" v-if="logistics.isCommercial">{{
                  $t("logistics.business_site")
                }}</a-tag>
                <a-tag color="warning" v-else>{{
                  $t("logistics.residence")
                }}</a-tag>
              </template>
            </div>

            <a-form-item class="mt-3" v-show="logistics.isShowVATInput">
              <template #label>
                <div>
                  <span style="color: red"> * </span>
                  <label>VAT</label>
                </div>
              </template>
              <a-input
                style="max-width: 250px"
                :disabled="true"
                v-model:value="logistics.VAT"
              />
            </a-form-item>
          </div>
        </div>
        <!-- 报关方式 和上传的文件-->
        <div class="mt-5" v-if="isSelectedCustoms">
          <p>
            <strong>{{ $t("logistics.clearance_way") }}</strong
            >: {{ $t("logistics.ordinary_trade_clearance") }}
          </p>
          <a-row :gutter="[32, 8]">
            <a-col v-for="(item, index) in logistics.imgUrl" :key="index">
              <div v-if="item.url">
                <div class="text-center">{{ $t(item.name) }}</div>
                <div>
                  <CFileDisplay :fileUrl="item.url"></CFileDisplay>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- button创建计划 -->
        <div class="mr-5 mb-5">
          <a-row type="flex" justify="end">
            <a-col>
              <a-button @click="handleBacktrack">{{
                $t("common.backtrack")
              }}</a-button>
            </a-col>
            <a-col class="ml-3">
              <a-button @click="handleSave('save')">{{
                $t("common.save")
              }}</a-button>
            </a-col>
            <a-col class="ml-3">
              <a-button type="primary" @click="handleSave('placeOrder')">{{
                $t("logistics.save_and_order")
              }}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <a-modal
        width="600px"
        v-model:visible="messageModal.visible"
        :title="$t('common.hint')"
        :okText="$t('common.immediate_processing')"
        :cancelText="$t('common.process_later')"
        :confirm-loading="messageModal.loading"
        :maskClosable="false"
        :centered="true"
        @ok="handleNextStep(true)"
        @cancel="handleNextStep(false)"
      >
        <a-row>
          <a-col :span="24">
            {{ $t("warehouse.jump_to_in_plan_prompt") }}
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick } from "vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Upload,
  Popover,
  Table,
  Input,
  Checkbox,
  Select,
  Button,
  Form,
  Spin,
  Space,
  message,
  Modal,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import UserDefinedBoxNo from "@/views/components/UserDefinedBoxNo.vue";
import CTagMixedOrSingle from "@/views/components/CTagMixedOrSingle.vue";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import localPrint from "@/utils/localPrint";
import urlHelper from "@/utils/urlHelper";
import { useTab } from "@/hooks/tabs/index";
import { getGlobalCountrys, getCurrencys } from "@/api/modules/common/index";
import {
  getTransportPlanDetails,
  updatePlanName,
  savePlanLastStep,
} from "@/api/modules/transportation/index";
import {
  batchPrintBoxOfPlanTransport,
  printBoxOfPlanTransport,
} from "@/api/modules/printUrl/pdf.js";
import {
  getAddressByLanguageV2,
  getLinkman,
  gToKg,
  cmCubicToM,
  getName,
  replaceBrackets,
  verifyMobilePhoneNumber,
  isValidGenericPhoneNumber,
} from "@/utils/general";
import {
  destinationType as destinationTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  warehouseType as warehouseTypeEnum,
} from "@/enum/enum.json";

export default {
  name: "transport_confirm_plan",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ACheckbox: Checkbox,
    AUpload: Upload,
    APopover: Popover,
    ASpace: Space,
    AModal: Modal,
    Content,
    CImage,
    CTooltip,
    CFileDisplay,
    UserDefinedBoxNo,
    CTagMixedOrSingle,
    CHaveProductInformation,
    CSignSupported,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const refCustomsDeclaration = ref();
    const refContract = ref();
    const refInvoice = ref();
    const refPackingList = ref();
    const { getters } = useStore();
    const refPlanName = ref();
    const currentPageIndex = ref(1);

    const boxTableColumns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ record }) => {
          return record.serialNumber;
        },
      },
      {
        title: vueI18n.t("warehouse.box_no"),
        width: 150,
        slots: {
          customRender: "boxNo",
        },
      },
      { width: 50 },
      {
        slots: {
          title: "encasementCount",
          customRender: "encasementCountCustom",
        },
        width: 100,
      },
      {
        slots: {
          title: "encasementSize",
          customRender: "encasementSizeCustom",
        },
        width: 150,
      },
      {
        slots: {
          title: "encasementGrossWeight",
          customRender: "encasementGrossWeightCustom",
        },
        width: 100,
      },
      {
        slots: {
          title: "print",
          customRender: "printCustom",
        },
        width: 150,
      },
    ];

    const innerProductColumns = [
      { width: 60 },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 200,
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount",
        },
      },
      {
        width: 500,
        dataIndex: "rests",
        title: vueI18n.t("warehouse.rests"),
        align: "right",
        slots: {
          customRender: "restsCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 100,
      },
      {
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 180,
      },
      {
        dataIndex: "optionalServices", //可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 180,
      },
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ];

    const customsInfoColumns = [
      {
        title: vueI18n.t("warehouse.serial_number"),
        dataIndex: "serialNumber ",
        slots: {},
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
        width: 150,
      },
      {
        title: vueI18n.t("logistics.trade_name"),
        dataIndex: "tradeName",
        slots: {
          customRender: "tradeNameCustom",
        },
        width: 150,
      },
      {
        title: vueI18n.t("warehouse.texture"),
        slots: {
          customRender: "texture",
        },
        width: 150,
      },
      {
        title: vueI18n.t("warehouse.brand_name"),
        slots: {
          customRender: "brand",
        },
        width: 150,
      },
      {
        title: vueI18n.t("warehouse.purpose_description"),
        slots: {
          customRender: "purposeDescription",
        },
        width: 250,
      },
      {
        title: "HS Code",
        dataIndex: "hsCode",
        width: 150,
      },
      {
        title: vueI18n.t("logistics.apply_middle_rate"),
        slots: {
          customRender: "applyMiddleRate",
        },
        width: 150,
      },
      {
        title: vueI18n.t("logistics.number"),
        dataIndex: "number",
        width: 150,
      },
      {
        title: vueI18n.t("logistics.subtotal"),
        slots: {
          customRender: "subtotal",
        },
        width: 150,
      },
    ];

    const state = reactive({
      destinationTypeEnum,
      pageLoading: false,
      bulkPrintLoading: false,
      buttonCreatePlanLoading: false,
      buttonSavePlanNameLoading: false,
      isChangingPlanName: false,
      currencies: [],
      phoneCountryLoading: false,
      phoneCountryList: [],
      plan: {
        boxTableColumns,
        innerColumns: innerProductColumns,
        id: "",
        no: "",
        name: "",
        toWarehouseType: null,
        toWarehouseId: "",
        toWarehouseCode: "",
        toNikeName: "",
        fbaTransportNo: "",
        productTrackNo: "",
        minShippingWeight: null,
        fromAddressData: {},
        toAddressData: {},
        producTableList: [],
        boxTotalCount: 0,
        transportTotalVolume: 0,
        transportTotalWeight: 0,
        totalContainerVolume: 0,
        totalContainerWeight: 0,

        transportTotalCharacteristic: null,
        isShowDoorTake: false, //是否支持上门揽件
        isSelectDoorTake: false, //是否上门揽件
        takeName: "",
        takePhone: "",

        toName: "",
        toPhone: "",
        receiptPhoneCountryId: "",

        seLogisticsAddress: "",
        seLogisticsContact: "",
        seLogisticsPhone: "",
      },

      customsInfo: [],
      customs: {
        totalFee: "",
        symbol: "",
      },

      logisticsSchemeLoading: false,
      logistics: {
        columnsLogisticsScheme: [],
        selectedLogisticsSchemeId: null,
        logisticsSchemeList: [],
        isShowVATInput: false,

        VAT: null,
        isSelectedDDP: false,
        isSelectedSignFee: false,
        imgUrl: [],
        isRemoteArea: null, //是否为偏远地址
        isCommercial: null, //是否为商业地址
      },
      isSelectedCustoms: false,
    });

    const checkPhoneNumber = () => {
      state.plan.toPhone = replaceBrackets(state.plan.toPhone);
    };

    //币种
    const funcGetCurrencies = () => {
      return getCurrencys({ isActive: true }).then((obj) => {
        let { result } = obj;
        state.currencies = result;
      });
    };

    // 国家
    const getCountriesList = () => {
      state.phoneCountryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          state.phoneCountryList = result;
        })
        .catch(() => {})
        .finally(() => {
          state.phoneCountryLoading = false;
        });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return "-";
      }
      return list.map((item) => item.name).join(", ");
    };

    const handleParseTime = (num) => {
      let number = Number.parseInt(num);
      return Number.isNaN(number) ? num : number;
    };

    const funcSearchPlanInfo = async (planId) => {
      state.pageLoading = true;
      getTransportPlanDetails({ id: planId })
        .then((res) => {
          let r = res.result;
          state.plan.id = r.planBasicInfo.planId;
          state.plan.no = r.planBasicInfo.planNo;
          state.plan.name = r.planBasicInfo.planName;
          state.plan.minShippingWeight =
            r.planBasicInfo.collectionMinimumWeight;
          state.plan.toWarehouseType = r.planBasicInfo.destinationType; //目的仓库类型
          state.plan.toWarehouseId = r.planBasicInfo.toWarehouseId;
          state.plan.toWarehouseCode = r.planBasicInfo.warehouseCode;
          state.plan.toNikeName = r.planBasicInfo.toName;
          state.plan.fbaTransportNo = r.planBasicInfo.amazonTransportNo;
          state.plan.productTrackNo = r.planBasicInfo.trackNo;
          state.plan.fromAddressData = r.planBasicInfo.fromAddress;
          state.plan.fromAddressData.fromName = r.planBasicInfo.fromName;
          state.plan.toAddressData = r.planBasicInfo.toAddress;
          state.plan.toAddressData.isInternationalTransport =
            r.planBasicInfo.isInternationalTransport;
          state.plan.seLogisticsAddress =
            r.planBasicInfo.logisitcWarehouseAddress;
          state.plan.seLogisticsPhone =
            r.planBasicInfo.logisitcWarehouseAddress.linkPhone;
          state.plan.seLogisticsContact =
            r.planBasicInfo.logisitcWarehouseAddress.linkUserName;
          state.plan.isShowDoorTake = r.planBasicInfo.isHaveDoorTook;
          state.plan.isSelectDoorTake = r.planBasicInfo.isDoorTook;
          state.plan.takeName = r.planBasicInfo.doorLinkUserName;
          state.plan.takePhone = r.planBasicInfo.doorContactPhone;
          // 收件人
          // 如果是赛易仓库
          if (
            r.planBasicInfo.destinationType === destinationTypeEnum.SeWarehouse
          ) {
            state.plan.toName = r.planBasicInfo.toAddress.linkMan ?? null;
          }

          state.plan.toName =
            state.plan.toName || r.planBasicInfo.receiptUserName;

          if (
            r.planBasicInfo.destinationType === destinationTypeEnum.FBA &&
            (!state.plan.toName || state.plan.toName.length == 0)
          ) {
            state.plan.toName = "amazon.com Services LLC";
          }

          if (
            r.planBasicInfo.destinationType ===
              destinationTypeEnum.mercadolibre &&
            (!state.plan.toName || state.plan.toName.length == 0)
          ) {
            state.plan.toName = "Mercado Libre";
          }

          state.plan.receiptPhoneCountryId =
            r.planBasicInfo.receiptPhoneCountryId;
          state.plan.toPhone = r.planBasicInfo.notCombineTelCodeContactPhone;
          // --------------------------
          state.plan.logisticsWarehouseNo =
            r.planBasicInfo.logisticsWarehouseNo;
          state.isSelectedCustoms = r.isUploadHsInfo;
          state.plan.producTableList = r.planBoxInfo.transportPlanBoxData.map(
            (x, index) => {
              x.loading = false;
              x.key = index + 1;
              x.serialNumber = index + 1;
              return x;
            }
          );

          state.plan.boxTotalCount = r.planBoxInfo.totalBox ?? "0";
          state.plan.transportTotalVolume = r.planBoxInfo.totalVolume
            ? cmCubicToM(r.planBoxInfo.totalVolume)
            : 0;
          state.plan.transportTotalWeight = r.planBoxInfo.totalBoxWeight
            ? gToKg(r.planBoxInfo.totalBoxWeight)
            : 0;
          state.plan.transportTotalCharacteristic =
            r.planBoxInfo.transportCharacteristics;
          state.plan.totalContainerVolume = r.planBoxInfo.totalContainerVolume
            ? cmCubicToM(r.planBoxInfo.totalContainerVolume)
            : 0;
          state.plan.totalContainerWeight = r.planBoxInfo.totalContainerWeight
            ? gToKg(r.planBoxInfo.totalContainerWeight)
            : 0;

          state.logistics.isRemoteArea =
            r.transportPlanLogistic.logisticsBaseInfo?.isRemoteArea;
          state.logistics.isCommercial =
            r.transportPlanLogistic.logisticsBaseInfo?.isCommercial;

          let columns = innerProductColumns;
          if (!state.plan.toAddressData.isInternationalTransport) {
            columns = innerProductColumns.filter((x) => x.dataIndex != "rests");
          } else if (
            state.plan.toWarehouseType != state.destinationTypeEnum.SeWarehouse
          ) {
            columns = columns.filter((x) => x.dataIndex != "print");
          }
          state.plan.innerColumns = columns;
          if (r.customsWayInfo) {
            if (r.customsWayInfo.integrationUrl) {
              state.logistics.imgUrl = [
                {
                  name: "logistics.four_in_one_file",
                  url: r.customsWayInfo.integrationUrl,
                },
              ];
            } else {
              state.logistics.imgUrl = [
                {
                  name: "logistics.customs_declaration",
                  url: r.customsWayInfo.customsDeclarationUrl,
                },
                {
                  name: "logistics.contract",
                  url: r.customsWayInfo.contractUrl,
                },
                {
                  name: "logistics.invoice",
                  url: r.customsWayInfo.invoiceUrl,
                },
                {
                  name: "logistics.packing_documents",
                  url: r.customsWayInfo.packingUrl,
                },
              ];
            }
          }

          state.customsInfo = r.customsInfo?.customsInfoCollect?.map(
            (x, index) => {
              return {
                key: index + 1,
                productName: x.productName,
                productNo: x.productNo,
                enTradeNameCustom: x.brandEnName,
                chTradeNameCustom: x.brandCName,
                enMaterial: x.enMaterial,
                cnMaterial: x.cnMaterial,
                brand: x.brand,
                purposeEnDescription: x.purposeEnDescription,
                purposeCnDescription: x.purposeCnDescription,
                currencySymbol: x.currencySymbol,
                applyMiddleRate: x.averagePrice,
                number: x.unit,
                hsCode: x.hsCode,
                subtotal: x.totalPrice,
              };
            }
          );

          let logisticsTemp = [r.transportPlanLogistic];

          state.logistics.logisticsSchemeList = logisticsTemp.map(
            (x, index) => {
              if (index == 0) {
                state.logistics.isShowVATInput =
                  x.logisticsRegionInfo.isVatRequired; //是否支持Vat
                state.logistics.VAT = x.logisticsRegionInfo.vatCode;
              }

              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                  ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                  : "0", //计费重
                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,
                logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字

                // 卡车相关
                feeCalculationMethod: x.logisticsBaseInfo?.feeCalculationMethod,
                truckBaseInfo: x.logisticsBaseInfo?.truckBaseInfo,
              };
            }
          );

          if (state.logistics.logisticsSchemeList.length) {
            let logisticsSchemeItem = state.logistics.logisticsSchemeList[0];
            if (
              logisticsSchemeItem.feeCalculationMethod ===
              feeCalculationMethodEnum.template
            ) {
              // 物流
              state.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
            } else {
              // 卡车
              state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
            }
          }
        })
        .catch((res) => {})
        .finally(() => {
          state.pageLoading = false;
        });
    };

    const handleSavePlanName = async () => {
      let par = {
        id: state.plan.id,
        planName: state.plan.name,
      };
      state.buttonSavePlanNameLoading = true;
      updatePlanName(par)
        .then((res) => {
          message.success(vueI18n.t("common.amend_success"));
          state.isChangingPlanName = false;
        })
        .finally(() => {
          state.buttonSavePlanNameLoading = false;
        });
    };

    const handleCancelAlterPlanName = async () => {
      state.plan.name = state.tempPlanName;
      state.isChangingPlanName = false;
    };

    const handleButtonAlterPlanName = async () => {
      state.isChangingPlanName = true;
      state.tempPlanName = state.plan.name;
      nextTick(() => {
        refPlanName.value.focus();
      });
    };

    const checkFrom = () => {
      if (state.plan.isSelectDoorTake) {
        if (!state.plan.takeName) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.shipments_contacts"),
            ])
          );
          return false;
        }
        if (!state.plan.takePhone) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.send_out_contact_number"),
            ])
          );
          return false;
        }
        if (!isValidGenericPhoneNumber(state.plan.takePhone)) {
          message.error(
            vueI18n.t("common.p0_incorrect_format", [
              vueI18n.t("logistics.send_out_contact_number"),
            ])
          );
          return false;
        }
      }

      if (!state.plan.toName) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.recipients"),
          ])
        );
        return false;
      }

      let receiptUserNameReg = /^.{1,35}$/;
      if (!receiptUserNameReg.test(state.plan.toName)) {
        message.error(
          vueI18n.t("common.p0_up_to_p1_characters", [
            vueI18n.t("logistics.recipients"),
            35,
          ])
        );
        return false;
      }

      if (state.plan.toPhone || state.plan.receiptPhoneCountryId) {
        if (!state.plan.receiptPhoneCountryId) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.area_code"),
            ])
          );
          return false;
        }
        if (!state.plan.toPhone) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.contact_number"),
            ])
          );
          return false;
        }
        let selectCountry = state.phoneCountryList.find(
          (item) => item.id === state.plan.receiptPhoneCountryId
        );
        let countryIos2Code = selectCountry?.ioS2 ?? null;
        if (
          !verifyMobilePhoneNumber(
            countryIos2Code,
            (selectCountry?.telCode ?? "") + state.plan.toPhone
          )
        ) {
          message.error(
            vueI18n.t("common.p0_incorrect_format", [
              vueI18n.t("logistics.receiving_contact_number"),
            ])
          );
          return false;
        }
      }

      return true;
    };

    const handleBacktrack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/transport/setplaninfo/" + state.plan.id });
    };

    const handleSave = (type) => {
      if (!checkFrom()) {
        return false;
      }
      let saveMode = null;
      if (type == "save") {
        saveMode = 1;
      } else if (type == "placeOrder") {
        saveMode = 2;
      }
      let pra = {
        id: state.plan.id,
        isDoorTook: state.plan.isSelectDoorTake,
        doorLinkUserName: state.plan.takeName,
        doorContactPhone: state.plan.takePhone,
        receiptUserName: state.plan.toName,
        receiptContactPhone: state.plan.toPhone,
        receiptPhoneCountryId: state.plan.receiptPhoneCountryId,
        saveMode: saveMode,
      };

      state.pageLoading = true;
      savePlanLastStep(pra)
        .then(({ result }) => {
          if (
            saveMode == 2 &&
            result.inPlanId &&
            result.toWarehouseType != warehouseTypeEnum.transitWarehouse
          ) {
            messageModal.visible = true;
            messageModal.id = result.inPlanId;
            messageModal.toWarehouseType = result.toWarehouseType;
          } else {
            delVisitedRoute(router.currentRoute.value);
            router.push({ path: "/transport/planlist" });
            message.success(vueI18n.t("common.succeed"));
          }
        })
        .finally(() => {
          state.pageLoading = false;
        });
    };

    const handleBulkPrint = () => {
      state.bulkPrintLoading = true;
      let boxLabelPdfUrls = batchPrintBoxOfPlanTransport(state.plan.id);
      localPrint(boxLabelPdfUrls, "pdf")
        .catch((error) => {
          state.bulkPrintLoading = false;
        })
        .finally(() => {
          state.bulkPrintLoading = false;
        });
    };

    const handlePrintBoxLabel = async (record) => {
      record.loading = true;
      let boxLabelPdfUrl = printBoxOfPlanTransport(
        state.plan.id,
        record.planDetailId
      );
      localPrint(boxLabelPdfUrl, "pdf")
        .catch((error) => {
          record.loading = false;
        })
        .finally(() => {
          record.loading = false;
        });
    };

    const messageModal = reactive({
      loading: false,
      visible: false,
      id: null,
    });

    const handleNextStep = (immediateProcessing) => {
      delVisitedRoute(router.currentRoute.value);
      if (
        immediateProcessing &&
        messageModal.toWarehouseType == warehouseTypeEnum.consignmentWarehouse
      ) {
        router.push({
          name: "consignment_inbound_confirm",
          params: { id: messageModal.id },
        });
      } else if (
        immediateProcessing &&
        messageModal.toWarehouseType == warehouseTypeEnum.storageWarehouse
      ) {
        router.push({
          name: "storage_inbound_detail",
          params: { id: messageModal.id },
        });
      } else {
        router.push({ path: "/transport/planlist" });
      }
      message.success(vueI18n.t("common.succeed"));
    };

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        state.plan.id = planId;
        funcSearchPlanInfo(planId);
        funcGetCurrencies();
        getCountriesList();
      }
    });

    return {
      ...toRefs(state),
      customsInfoColumns,
      refCustomsDeclaration,
      refContract,
      refInvoice,
      refPackingList,
      refPlanName,
      currentPageIndex,

      gToKg,
      urlHelper,
      getLinkman,
      messageModal,
      checkPhoneNumber,
      getAddress,
      getAccessorialsName,
      handleParseTime,
      handleSavePlanName,
      handleCancelAlterPlanName,
      handleButtonAlterPlanName,
      handleBacktrack,
      handleSave,
      handleBulkPrint,
      handlePrintBoxLabel,
      getLanguageName,
      handleNextStep,
    };
  },
};
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
</style>
