import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {

  getTrackList:"/api/WarehouseService/TransportPlan/QueryTracks",
  // 运输计划创建
  //第一步创建
  getFbaOrFbmWarehouse: "/api/BasicDataService/PlatformAddress/GetList",
  checkSupportTransit:
    "/api/WarehouseService/Warehouse/CheckHasWarehouseTypeWithReturn",
  createTransportPlan: "/api/WarehouseService/TransportPlan/Create",
  getGenerateTemplate: "/api/WarehouseService/InWarehousePlan/GenerateTemplate",

  //第二步设置运输信息
  getTransportPlanDetails: "/api/WarehouseService/TransportPlan/GetDetail",
  updatePlanName: "/api/WarehouseService/TransportPlan/UpdatePlanName",
  getCustomsInfo: "/api/ProductService/CustomsDeclaration/GetDefault",
  getLogisticsScheme: "/api/WarehouseService/WarehouseLogistics/TransportPlanGetLogistic",
  uploadFile: "/api/BasicDataService/AliyunOSS/UploadToTemp",
  deleteTransportPlan: "/api/WarehouseService/TransportPlan/Delete",
  savePlanSetBoxInfo: "/api/WarehouseService/TransportPlan/SetTransportInfo",
  getLogisticsAddressInfo: "/api/WarehouseService/TransportPlan/GetTransportPlanLogisticesFeeDetail",
  updateTransportPlanBoxInfo: "/api/WarehouseService/TransportPlan/UpdateBoxInfo",
  //第三步
  savePlanLastStep: "/api/WarehouseService/TransportPlan/Confirm",
  payOrder: "/api/WarehouseService/TransportPlan/ConfirmPayment",

  //列表
  getTransportPlanList: "/api/WarehouseService/TransportPlan/GetPlanPaged",
  createPlanModalOk: "/api/WarehouseService/TransportPlan/CreateInWarehousePlan",
  // 取消运输计划
  cancelTransportPlan: "/api/WarehouseService/TransportPlan/Cancel",

  // 到付
  confirmPayOnArrival: "/api/WarehouseService/TransportPlan/ConfirmPayOnArrival",
  // 获取卡车费用
  getTruckFee: "/api/WarehouseService/TransportPlan/GetTruckFee",
  // 导出
  exportTransportPlan: "/api/WarehouseService/TransportPlan/Export",
  exportPackingList: "/api/WarehouseService/TransportPlan/ExportBox",
  exportPlanBoxes: "/api/WarehouseService/TransportPlan/ExportPlanBoxes",

  // 存储仓/配送仓入库创建运输计划
  // 判断是否需要补齐产品
  checkIsNeedProduct: "/api/WarehouseService/InWarehousePlan/CheckIsNeedProduct",
  // 创建运输计划 - 不需要补齐产品
  createTransportPlanByUnNeedProduct: "/api/WarehouseService/InWarehousePlan/CreateTransportPlanByUnNeedProduct",
  // 创建运输计划 - 需要补齐产品
  createTransportPlanByNeedProduct: "/api/WarehouseService/InWarehousePlan/CreateTransportPlanByNeedProduct",
  // 创建运输计划 - 批量导入
  // 下载模板
  generateTransportPlanTemplate: "/api/WarehouseService/TransportPlan/GenerateTemplate",
  // 解析Excel
  parseTransportPlanExcelData: "/api/WarehouseService/TransportPlan/ParseExcelData",
  // 数据导入
  importTransportPlanData: "/api/WarehouseService/TransportPlan/ImportData",
  // 批量打印入仓单
  batchSeInWarehouseOrder: "/api/WarehouseService/TransportPlan/GetBatchSEInWarehourseOrderPdf/batchseinwarehourseorder.pdf",
  // 批量打印账单
  printTransportBill: "/api/WarehouseService/TransportPlan/GetTransportBillPdf/TransportBill.pdf",
  // 导出账单
  exportTransportBill: "/api/WarehouseService/TransportPlan/ExportTransportBill",
};

export const getTrackList = ({planId,warehouseId}) => {
  return client.request({
    url: Api.getTrackList,
    data: {planId,warehouseId},
    method: "post",
  });
};

export const getTransportPlanList = ({
  operateType = 1,
  queryTimeType=1,
  searchKey,
  destinationType,
  fromCountryId,
  fromCityId,
  toCountryId,
  toCityId,
  startCreationTime,
  endCreationTime,
  planStatus,
  isLoseMoney,
  inOrOutWarehouseNo,
  skipCount,
  isNeedTransportBoxesTotal,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getTransportPlanList,
    data: {
      operateType,
      queryTimeType,
      searchKey,
      destinationType,
      fromCountryId,
      fromCityId,
      toCountryId,
      toCityId,
      startCreationTime,
      endCreationTime,
      planStatus,
      isLoseMoney,
      inOrOutWarehouseNo,
      skipCount,
      isNeedTransportBoxesTotal,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getFbaOrFbmWarehouse = (data) => {
  return client.request({
    url: Api.getFbaOrFbmWarehouse,
    data: data,
    method: "post",
  });
};

export const checkSupportTransit = ({ warehouseId, warehouseType }) => {
  return client.request({
    url: Api.checkSupportTransit,
    data: { warehouseId, warehouseType },
    method: "post",
  });
};

export const createTransportPlan = ({
  planName,
  destinationType,
  toWarehouseType,
  toWarehouseId,
  amazonTransportNo,
  trackNo,
  transportPlanDetails,
  fromAddress,
  toAddress,
}) => {
  return client.request({
    url: Api.createTransportPlan,
    data: {
      planName,
      destinationType,
      toWarehouseType,
      toWarehouseId,
      amazonTransportNo,
      trackNo,
      transportPlanDetails,
      fromAddress,
      toAddress,
    },
    method: "post",
  });
};

export const getGenerateTemplate = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.getGenerateTemplate);
};

export const getTransportPlanDetails = ({ id }) => {
  return client.request({
    url: Api.getTransportPlanDetails,
    data: { id },
    method: "post",
  });
};

export const updatePlanName = ({ id, planName }) => {
  return client.request({
    url: Api.updatePlanName,
    data: { id, planName },
    method: "post",
  });
};

export const getCustomsInfo = ({ productId,countryId }) => {
  return client.request({
    url: Api.getCustomsInfo,
    params: { productId,countryId },
    method: "get",
  });
};

export const getLogisticsScheme = ({ planId }) => {
  return client.request({
    url: Api.getLogisticsScheme,
    data: { planId },
    method: "post",
  });
};

export const uploadFile = (data) => {
  return client.request({
    url: Api.uploadFile,
    data: data,
    method: "post",
  });
};

export const deleteTransportPlan = ({ id }) => {
  return client.request({
    url: Api.deleteTransportPlan,
    data: { id },
    method: "post",
  });
};

export const savePlanSetBoxInfo = ({
  id,
  customsDeclarationUrl,
  contractUrl,
  invoiceUrl,
  packingUrl,
  integrationUrl,
  logisticsId,
  isChooseDdp,
  isChooseSign,
  signatureType,
  vatCode,
  transportPlanDetails,
  isUploadHsInfo,
  truckApiLogistics,
}) => {
  return client.request({
    url: Api.savePlanSetBoxInfo,
    data: {
      id,
      customsDeclarationUrl,
      contractUrl,
      invoiceUrl,
      packingUrl,
      integrationUrl,
      logisticsId,
      isChooseDdp,
      isChooseSign,
      signatureType,
      vatCode,
      transportPlanDetails,
      isUploadHsInfo,
      truckApiLogistics,
    },
    method: "post",
  });
};

export const getLogisticsAddressInfo = ({
  planId,
  logisticsId,
  deliveryType,
  truckApiLogistics,
}) => {
  return client.request({
    url: Api.getLogisticsAddressInfo,
    data: {
      planId,
      logisticsId,
      deliveryType,
      truckApiLogistics
    },
    method: "post",
  });
};

export const updateTransportPlanBoxInfo = (data) => {
  return client.request({
    url: Api.updateTransportPlanBoxInfo,
    data,
    method: "POST",
  });
};

export const savePlanLastStep = ({
  id,
  isDoorTook,
  doorLinkUserName,
  doorContactPhone,
  receiptUserName,
  receiptContactPhone,
  receiptPhoneCountryId,
  saveMode,
}) => {
  return client.request({
    url: Api.savePlanLastStep,
    data: {
      id,
      isDoorTook,
      doorLinkUserName,
      doorContactPhone,
      receiptUserName,
      receiptContactPhone,
      receiptPhoneCountryId,
      saveMode,
    },
    method: "post",
  });
};

export const payOrder = ({ id,payFee }) => {
  return client.request({
    url: Api.payOrder,
    data: { id,payFee },
    method: "post",
  });
};

export const cancelTransportPlan = (data) => {
  return client.request({
    url: Api.cancelTransportPlan,
    data,
    method: "POST",
  });
};

export const confirmPayOnArrival = (data) => {
  return client.request({
    url: Api.confirmPayOnArrival,
    data,
    method: "POST",
  });
};

export const getTruckFee = (data) => {
  return client.request({
    url: Api.getTruckFee,
    data,
    method: "POST",
  });
};

export const exportTransportPlan = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransportPlan);
  return url;
}

export const exportPackingList = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportPackingList);
  return url;
}

export const exportPlanBoxes = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportPlanBoxes);
  url = urlHelper.addQueryString(url, "id", data.planId);
  return url;
}


export const createPlanModalOk = (id, warehouseId) => {
  return client.request({
    url: Api.createPlanModalOk,
    data: {
      id, warehouseId
    },
    method: "post",
  });
}

export const checkIsNeedProduct = (data) => {
  return client.request({
    url: Api.checkIsNeedProduct,
    data,
    method: "POST",
  });
};

export const createTransportPlanByUnNeedProduct = (data) => {
  return client.request({
    url: Api.createTransportPlanByUnNeedProduct,
    data,
    method: "POST",
  });
};

export const createTransportPlanByNeedProduct = (data) => {
  return client.request({
    url: Api.createTransportPlanByNeedProduct,
    data,
    method: "POST",
  });
};

export const generateTransportPlanTemplate = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.generateTransportPlanTemplate);
  return url;
};

export const parseTransportPlanExcelData = (data) => {
  return client.request({
    url: Api.parseTransportPlanExcelData,
    data,
    method: "POST",
  });
};

export const importTransportPlanData = (data) => {
  return client.request({
    url: Api.importTransportPlanData,
    data,
    method: "POST",
  });
};

export const batchSeInWarehouseOrder = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchSeInWarehouseOrder
  );
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}

export const printTransportBill = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.printTransportBill
  );
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}

export const exportTransportBill = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransportBill);
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}